export default {
  "common.menuInterface": "菜单接口",
  "common.edit": "编辑",
  "common.enable": "启用",
  "common.disable": "禁用",
  "common.interfaceList": "接口列表",
  "common.assignMenu": "分配菜单",
  "common.newMenu": "新增菜单",
  "common.newRole": "新增角色",
  "common.authorizationRole": "授权角色",
  "common.newUser": "新增用户",
  "common.more": "更多",
  "common.changePassword": "修改密码",
  "common.audit": "审计",
  "common.addNew": "新建",
  "common.defaultTemplate": "默认模板",
  "common.manualRecharge": "手动充值",
  "common.MANUALY": "手动",
  "common.qpay": "QPAY",
  "common.goodsDetail": "货物详细信息",
  "common.viewDetail": "查看详情",
  "common.ban": "封禁",
  "common.unban": "解封",
  "common.viewOrderFinance": "查看金融产品",
  "common.viewOrderGoods": "查看订单商品",
  "common.normal": "正常",
  "common.abnormal": "禁用",
  "common.auditing": "审核中",
  "common.audited": "审核通过",
  "common.auditFailed": "审核不通过",
  "common.bannerOn": "启用",
  "common.bannerOff": "禁用",
  "common.on": "启用",
  "common.off": "禁用",
  "common.chooseCategory": "选择类别",
  "common.bannerDelete": "已删除",
  "common.okText": "确认",
  "common.cancelText": "取消",
  "common.tip": "提示",
  "common.messageSuccess": "{{action}} 成功",
  "common.unbind": "解绑",
  "common.operation": "手术",
  "common.created": "创建",
  "common.updated": "更新",
  "common.deleted": "删除",
  "common.HOME": "主页",
  "common.OTHER": "其他",
  "common.PROMOTION": "推广挣钱",
  "common.WALLET": "钱包",
  "common.MEMBER": "会员中心",
  "common.EXTERNAL": "外部链接",
  "common.MERCHANT_APPLY": "商户申请",
  "common.GOODS": "商品",
  "common.MERCHANT": "商户主页",
  "common.delete": "删除",
  "common.sort": "排序",
  "common.PASS": "通过",
  "common.REJECT": "拒绝",
  "common.STOP": "停业",
  "common.OPEN": "营业中",
  "common.muteComment": "禁止评论",
  "common.levelOne": "一级",
  "common.levelTwo": "二级",
  "common.levelThree": "三级",
  "common.memberRebateSetting": "购买会员返佣比例设置",
  "common.goodsRebateSetting": "购买返利商品返佣比例设置",
  "common.memberAgentLevelSetting": "代理等级",
  "common.addGrade": "添加等级",
  "common.WAIT_PAY": "待支付",
  "common.SUCCEED": "成功(已经支付)",
  "common.manage": "管理",
  "common.directRewardSetting": "直推奖励设置",
  "common.newUserRewardSettings": "新用户奖励设置",
  "common.purchaseMembershipRewardSettings": "购买会员奖励设置",
  "common.WAIT": "等待审核",
  "common.FAIL": "失败",
  "common.reject": "审核驳回",
  "common.pass": "审核通过",
  "common.UNPUBLISHED": "未发布",
  "common.OFF": "下架",
  "common.ON": "上架",
  "common.buyVirtualGoodsRebateSettings": "购买展销返利列表",
  "common.depositInterestRebateSettings": "原始股返利列表",
  "common.INCOME": "收入",
  "common.EXPEND": "支出",
  "common.REBATE": "消费返利",
  "common.SHOP_MENU": "商店菜单",
  "common.FINANCIAL_PRODUCT": "金融产品",
  "common.COMMUNITY": "社区",
  "common.FINANCIAL_PRODUCT_REWARD": "金融产品奖励",
  "common.REBATE_COMMISSION": "消费返利提成",
  "common.WITHDRAW": "提现",
  "common.WITHDRAW_ROLLBACK": "提现回滚",
  "common.RECHARGE": "充值",
  "common.INTEREST": "共享收益",
  "common.INTEREST_CAPITAL": "原始股本金",
  "common.INTEREST_COMMISSION": "下级收益返利",
  "common.OPEN_SHOP": "开店",
  "common.CANCEL_SHOP": "关店",
  "common.SHOP_TRANSFER": "店铺转入",
  "common.BUY_ADVERTISEMENT": "购买广告",
  "common.BUY_FINANCIAL": "购买理财产品",
  "common.BUY_GOODS": "购买商品",
  "common.BUY_VIP": "购买贵宾",
  "common.BUY_VIP_COMMISSION": "购买vip返佣",
  "common.COMMISSION_TRANSFER": "佣金转入",
  "common.COMMISSION_TRANSFER_INCOME_SCORE": "来自收入分数的佣金转移",
  "common.COMMISSION_TRANSFER_SCORE": "佣金转移至评分",
  "common.COMMISSION_TRANSFER_INCOME_BALANCE": "来自收入余额的佣金转移",
  "common.COMMISSION_TRANSFER_BALANCE": "佣金转移至余额",
  "common.BEAN_TASK_REWARD": "完成任务",
  "common.FUN_SPORT_REWARD": "完成趣步任务",
  "common.FUN_TASK_REWARD": "任务乐趣完成",
  "common.BEAN_TRANSFER": "豆子转入",
  "common.BEAN_CLAIM_TASK": "使用豆子连接任务",
  "common.BEAN_CANCEL_TASK": "从取消任务获取豆子",
  "common.SALE_GOODS": "销售商品",
  "common.BUY_BEAN": "出售趣豆",
  "common.OLD_SYSTEM_RETURN": "旧系统回归",
  "common.SCORE_REWARD": "购买 VIP 即可获得积分奖励",
  "common.SYSTEM_REWARD": "系统奖励",
  "common.ORDER_GOODS_REFUND": "退款",
  "common.STOCK": "共享",
  "common.FUN_SPORT": "趣运动",
  "common.FUNTASK": "有趣的任务",
  "common.ORDER_LIST": "订单",
  "common.yes": "是",
  "common.no": "不",
  "common.banEndTime": "禁用结束时间",
  "common.GOODS_DETAIL": "商品详情",
  "common.choose_flag": "选择国旗",
  "common.reset": "重置",
  "common.submit": "提交",
  "common.confirmed": "确认的",
  "common.HOME_POPUP": "首页弹窗",
  "common.CAROUSELSHOP": "商店轮播",
  "common.CAROUSEL": "轮播图",
  "common.HOME_BANNER": "主页横幅",
  "common.SETTING_BANNER": "设置横幅",
  "common.KING_KONG": "金刚区",
  "common.totalRegistration": "全部注册(个)",
  "common.totalUserBuyVIP": "购买 VIP 的用户总数",
  "common.totalRechargeAmount": "充值金额(₫)",
  "common.totalRechargeCoin": "(USDT)充值金额",
  "common.totalRechargeDong": "(USDT)充值金额(₫)",
  "common.totalBankWithdraw": "银行提现(₫)",
  "common.totalUSDTWithdrawCoin": "USDT提现金额",
  "common.totalUSDTWithdrawDong": "USDT 提现金额(₫)",
  "common.SHOP_STATUS_PENDING": "待审核",
  "common.SHOP_STATUS_FAIL": "审核失败",
  "common.SHOP_STATUS_UNPAID": "未缴费",
  "common.SHOP_STATUS_CANCEL": "注销",
  "common.adminManagement": "运营后台",
  "common.banForever": "永远禁止",
  "common.ordinary": "普通",
  "common.vip": "VIP",
  "common.statisticData": "统计数据",
  "common.logout": "登出",
  "common.BALANCE": "余额",
  "common.INCOME_BALANCE": "返利余额",
  "common.SCORE": "积分",
  "common.INCOME_SCORE": "返利积分",
  "common.BEAN": "豆子",
  "common.clickHere": "点击这里",
  "common.password": "密码",
  "common.confirmPassword": "确认密码",
  "common.successModify": "修改成功",
  "common.assignRole": "分配角色",
  "common.addRole": "添加角色",
  "common.refuse": "拒绝",
  "common.refund": "退款",
  "common.goToRefund": "前往退款",
  "common.remove": "去除",
  "common.cancel": "取消",
  "common.viewSales": "View Sales",
  "common.viewProducts": "View Products",
  "common.updateDelivery": "更新交付",
  "common.set_default": "默认设置",
  "common.buy_member_reward_as_score": "购买会员奖励作为积分",
  "common.PAID": "支付",
  "common.total_withdraw_success": "总提款成功",
  "common.total_withdraw_waiting": "总提款等待",
  "common.total_Recharge_success": "总充值成功",
  "common.total_income": "总收入",
  "common.total_expend": "总支出",
  "common.note_create_task_configuration":
    "若要启用任务，请在相应字段中提供一个值。将字段留空将导致任务被禁用。",
  "common.invite_friends": "邀请朋友",
  "common.visit_store": "参观商店",
  "common.watch_video": "看视频",
  "common.answer_question": "回答问题",
  "common.read_news": "阅读新闻",
  "common.visit_flash_sale": "参观闪购",
  "common.promote_links": "和朋友分享",
  "common.bubble": "气泡释放",
  "common.totalPeopleJoinTask": "参与任务总人数",
  "common.totalScoreUserGet": "用户获得的总分",
  "common.totalAmount": "总金额",
  "common.export": "出口",
  "common.variation": "变化",
  "common.update_image": "更新图片",
  "common.save": "节省",
  "common.add_parent_variation": "添加父变体",
  "common.add_child_variation": "添加子变体",
  "common.add_variation": "添加变化",
  "common.edit_variation": "编辑变体",
  "common.edit_name": "编辑姓名",
  "common.edit_value": "编辑值",
  "common.approvalFailed": "审批失败！",
  "common.rejectWithdraw": "拒绝提款",
  "common.hindi": "印地语",
  "common.english": "英语",
};
