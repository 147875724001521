export default {
    'client_info': "Client Information",
    'nickname': 'Nickname',
    'mobile': 'Mobile',
    'product_info': 'Product Information',
    'original_price': 'Original Price',
    'express': 'Express',
    'refund_date': 'Refund Date',
    'quantity': 'Quantity',
    'product_refund': 'Product Refund',
    'amount_refund': 'Amount Refund',
    'province': 'Province',
    'district': 'District',
    'address': 'Address',
    'receiver': 'Receiver',
    'region': 'Region',
    'description': 'Description',
    'appeal_date': 'Appeal Date',
    'amount_rebate': 'Amount Rebate',
    "delivery_charge": "Delivery Charge",
    "address_location_info": "Address Location Information",
    "address_location": "Address Location ",
}