export default {
    'wait_review': 'Waiting Review',
    'review_fail': 'Review Failed',
    'review_scc': 'Review Success',
    'wait_return': 'Waiting Return',
    'return_fail': 'Return Failed',
    'return_succ': 'Return Success',
    'wait_refund': 'Waiting Refund',
    'refund_fail': 'Refund Failed',
    'refund_succ': 'Refund Success',
    'refund': 'Refund',
    'pending':'Pending',
    'checked': 'Checked',
    'responded': 'Responded',
    'feedback': 'Feedback',
    'complain': 'Complain',
    'good_review': 'Good Review',
    'about_app': 'About App',
    'running': 'RUNNING',
    'finished': 'FINISHED',
    'app': 'APP',
    'shipping': 'SHIPPING',
    "orderRebate": "Rebate in progress",
    "orderSuccess": "Processing completed",
    "orderWaitDelivery": "Waiting for delivery",
    "orderWaitUserPicked": "Waiting user come to pick up",
    "orderWaitReceived": "Waiting to receive",
    "orderRefund": "Refund",
}