export default {
    "modal.menuCreate": "New menu",
    "modal.menuUpdate": "Update Memu",
    "modal.parentMenu": "Parent menu",
    "modal.carouselCreate": "Create carousel",
    "modal.carouselUpdate": "Update carousel",
    "modal.areYouSure": "Are you sure to {{middle}}?",
    "modal.areYouSureToThisUser": "Are you sure to {{middle}} this user?",
    "modal.areYouSureWantToUnbind": "Are you sure want to unbind?",
    "modal.areYouSureWantToUnbindThisCharacter": "Are you sure to unbind this character?",
    "modal.createRole": "Create role",
    "modal.editRole": "Edit role",
    "modal.pleaseSelectItem": "Please select an item",
    "modal.createFinancialProduct": "Create Financial Product",
    "modal.updateFinancialProduct": "Update Financial Product",
    "modal.auditGood": "Audit good",
    "modal.edit": "Edit",
    "modal.update": "Update",
    "modal.create": "Create",
    "modal.template": "Template",
    "modal.viewDetail": "View detail",
    "modal.removeWarningMessage": "Are you sure to delete this ?",
    "modal.auditShop": "Audit shop",
    "modal.addLevel": "Add level",
    "modal.goodsOffOn": "Goods on and off",
    "modal.banThisUser": "Do you want to ban this user?",
    "modal.remove":"Are you sure to remove it?",
    "modal.messageConfirmCloseStore": "* After this action, the store will be closed, and all its products will no longer be displayed. This action cannot be undone. The user needs to reapply for the store. Are you sure you want to close this store?",
};
  