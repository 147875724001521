export default {
    "modal.menuCreate": "新增菜单",
    "modal.menuUpdate": "编辑菜单",
    "modal.parentMenu": "父级菜单",
    "modal.carouselCreate": "创建轮播图",
    "modal.carouselUpdate": "更新轮播图",
    "modal.areYouSure": "确定要{{middle}}吗?",
    "modal.areYouSureToThisUser": "您确定要{{middle}}此用户吗？",
    "modal.areYouSureWantToUnbind": "确定要解绑吗？",
    "modal.areYouSureWantToUnbindThisCharacter": "您确定要解除该角色的绑定吗？",
    "modal.createRole": "创建角色",
    "modal.editRole": "编辑角色",
    "modal.pleaseSelectItem": "请选择项目",
    "modal.createFinancialProduct": "创建金融产品",
    "modal.updateFinancialProduct": "更新金融产品",
    "modal.auditGood": "商品审核",
    "modal.edit": "编辑",
    "modal.update": "调整",
    "modal.create": "创建",
    "modal.template": "模板",
    "modal.viewDetail": "咨询详情",
    "modal.removeWarningMessage": "是否确实要删除此项目?",
    "modal.auditShop": "店铺审核",
    "modal.addLevel": "添加等级",
    "modal.goodsOffOn": "商品上架下架",
    "modal.banThisUser": "是否要禁止此用户？",
    "modal.remove":"您确定要删除它吗？",
    "modal.messageConfirmCloseStore": "* 完成此操作后，该店铺将被关闭，其所有产品将不再显示。此操作无法撤销。用户需要重新申请开设店铺。您确定要关闭该店铺吗",
};
  