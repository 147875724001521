export default {
  "common.menuInterface": "Menu interface",
  "common.edit": "Edit",
  "common.enable": "Enable",
  "common.disable": "Disable",
  "common.interfaceList": "Interface list",
  "common.assignMenu": "Assign Menu",
  "common.newMenu": "New menu",
  "common.newRole": "New role",
  "common.authorizationRole": "Authorize",
  "common.newUser": "New user",
  "common.more": "More",
  "common.changePassword": "Change password",
  "common.audit": "Audit",
  "common.addNew": "Add new",
  "common.defaultTemplate": "Default Template",
  "common.manualRecharge": "Manual recharge",
  "common.MANUALY": "MANUALY",
  "common.qpay": "QPAY",
  "common.goodsDetail": "Goods details",
  "common.viewDetail": "View detail",
  "common.ban": "Ban",
  "common.unban": "Unban",
  "common.viewOrderFinance": "View Financial Product",
  "common.viewOrderGoods": "View Order Goods",
  "common.normal": "Normal",
  "common.abnormal": "Abnormal",
  "common.auditing": "AUDITING",
  "common.audited": "AUDITED",
  "common.auditFailed": "AUDIT FAILED",
  "common.bannerOn": "ON",
  "common.bannerOff": "OFF",
  "common.on": "On",
  "common.off": "Off",
  "common.chooseCategory": "Choose category",
  "common.bannerDelete": "DELETE",
  "common.okText": "Confirm",
  "common.cancelText": "Cancel",
  "common.tip": "Tip",
  "common.messageSuccess": "{{action}} Success",
  "common.unbind": "Unbind",
  "common.operation": "Operation",
  "common.created": "Created",
  "common.updated": "Updated",
  "common.deleted": "DELETED",
  "common.HOME": "Home",
  "common.OTHER": "Other",
  "common.PROMOTION": "PROMOTION",
  "common.WALLET": "WALLET",
  "common.MEMBER": "MEMBER",
  "common.EXTERNAL": "EXTERNAL",
  "common.MERCHANT_APPLY": "MERCHANT APPLY",
  "common.GOODS": "GOODS",
  "common.MERCHANT": "MERCHANT",
  "common.delete": "Delete",
  "common.sort": "Sort",
  "common.PASS": "PASS",
  "common.REJECT": "REJECT",
  "common.STOP": "STOP",
  "common.OPEN": "OPEN",
  "common.muteComment": "Mute",
  "common.levelOne": "Level one",
  "common.levelTwo": "Level two",
  "common.levelThree": "Level three",
  "common.memberRebateSetting": "Member agent member rebate rate settings",
  "common.goodsRebateSetting": "Member agent goods rebate rate settings",
  "common.memberAgentLevelSetting": "Member agent level settings",
  "common.addGrade": "Add grade",
  "common.WAIT_PAY": "Wait pay",
  "common.SUCCEED": "Succeed",
  "common.manage": "Manage",
  "common.directRewardSetting": "Direct referral reward settings",
  "common.newUserRewardSettings": "New user reward settings",
  "common.purchaseMembershipRewardSettings":
    "Purchase membership reward settings",
  "common.WAIT": "WAIT",
  "common.FAIL": "FAIL",
  "common.reject": "Reject",
  "common.pass": "Pass",
  "common.UNPUBLISHED": "UNPUBLISHED",
  "common.OFF": "OFF",
  "common.ON": "ON",
  "common.buyVirtualGoodsRebateSettings": "Buy Virtual goods rebate rate",
  "common.depositInterestRebateSettings": "Deposit interest rebate rate",
  "common.INCOME": "INCOME",
  "common.EXPEND": "EXPEND",
  "common.REBATE": "REBATE",
  "common.SHOP_MENU": "SHOP MENU",
  "common.FINANCIAL_PRODUCT": "FINANCIAL PRODUCT",
  "common.COMMUNITY": "COMMUNITY",
  "common.FINANCIAL_PRODUCT_REWARD": "FINANCIAL PRODUCT REWARD",
  "common.REBATE_COMMISSION": "REBATE COMMISSION",
  "common.WITHDRAW": "WITHDRAW",
  "common.WITHDRAW_ROLLBACK": "WITHDRAW ROLLBACK",
  "common.RECHARGE": "RECHARGE",
  "common.INTEREST": "INTEREST",
  "common.INTEREST_CAPITAL": "INTEREST CAPITAL",
  "common.INTEREST_COMMISSION": "INTEREST COMMISSION",
  "common.OPEN_SHOP": "OPEN SHOP",
  "common.CANCEL_SHOP": "CANCEL SHOP",
  "common.SHOP_TRANSFER": "SHOP TRANSFER",
  "common.BUY_ADVERTISEMENT": "BUY ADVERTISEMENT",
  "common.BUY_FINANCIAL": "BUY FINANCIAL",
  "common.BUY_GOODS": "BUY GOODS",
  "common.BUY_VIP": "BUY VIP",
  "common.BUY_VIP_COMMISSION": "BUY VIP COMMISSION",
  "common.COMMISSION_TRANSFER": "COMMISSION TRANSFER",
  "common.COMMISSION_TRANSFER_INCOME_SCORE": "COMMISSION TRANSFER FROM INCOME SCORE",
  "common.COMMISSION_TRANSFER_SCORE": "COMMISSION TRANSFER TO SCORE",
  "common.COMMISSION_TRANSFER_INCOME_BALANCE": "COMMISSION TRANSFER FROM INCOME BALANCE",
  "common.COMMISSION_TRANSFER_BALANCE": "COMMISSION TRANSFER TO BALANCE",
  "common.BEAN_TASK_REWARD": "COMPLETE TASK",
  "common.FUN_SPORT_REWARD": "COMPLETE FUNSPORT MISSION",
  "common.FUN_TASK_REWARD": "COMPLETE FUNTASK MISSION",
  "common.BEAN_TRANSFER": "BEAN TRANSFER",
  "common.BEAN_CLAIM_TASK": "USE BEAN JOIN TASK",
  "common.BEAN_CANCEL_TASK": "GET BEAN FROM CANCEL TASK",
  "common.SALE_GOODS": "SALE GOODS",
  "common.BUY_BEAN": "BUY BEAN",
  "common.OLD_SYSTEM_RETURN": "OLD SYSTEM RETURN",
  "common.SCORE_REWARD": "SCORE REWARD FROM BUY VIP",
  "common.SYSTEM_REWARD": "SYSTEM REWARD",
  "common.ORDER_GOODS_REFUND": "GOODS REFUND",
  "common.STOCK": "STOCK",
  "common.FUN_SPORT": "FUN SPORT",
  "common.FUNTASK": "FUN TASK",
  "common.ORDER_LIST": "ORDER LIST",
  "common.yes": "Yes",
  "common.no": "No",
  "common.banEndTime": "Disable end time",
  "common.GOODS_DETAIL": "GOODS DETAIL",
  "common.choose_flag": "Go to select a flag",
  "common.reset": "Reset",
  "common.submit": "Submit",
  "common.confirmed": "Confirmed",
  "common.HOME_POPUP": "HOME POPUP",
  "common.CAROUSELSHOP": "Shop Carousel",
  "common.CAROUSEL": "CAROUSEL",
  "common.HOME_BANNER": "HOME BANNER",
  "common.SETTING_BANNER": "SETTING BANNER",
  "common.KING_KONG": "KING KONG",
  "common.totalRegistration": "Total Registration",
  "common.totalUserBuyVIP": "Total User Buy VIP",
  "common.totalRechargeAmount": "Total Bank Recharge(₮)",
  "common.totalRechargeCoin": "(USDT)Total Recharge coin",
  "common.totalRechargeDong": "(USDT)Total Recharge(₮)",
  "common.totalBankWithdraw": "Total Bank Withdraw(₮)",
  "common.totalUSDTWithdrawCoin": "(USDT)Total Withdraw coin",
  "common.totalUSDTWithdrawDong": "(USDT)Total Withdraw(₮)",
  "common.SHOP_STATUS_PENDING": "PENDING",
  "common.SHOP_STATUS_FAIL": "FAIL",
  "common.SHOP_STATUS_UNPAID": "UNPAID",
  "common.SHOP_STATUS_CANCEL": "CANCEL",
  "common.adminManagement": "Admin Management",
  "common.banForever": "Ban forever",
  "common.ordinary": "Normal",
  "common.vip": "VIP",
  "common.statisticData": "Statistical Data",
  "common.logout": "Logout",
  "common.BALANCE": "Balance",
  "common.INCOME_BALANCE": "Income Balance",
  "common.SCORE": "Score",
  "common.INCOME_SCORE": "Income Score",
  "common.BEAN": "Bean",
  "common.clickHere": "Click here",
  "common.password": "Password",
  "common.confirmPassword": "Confirm Password",
  "common.successModify": "Successfully Modified",
  "common.assignRole": "Assigning Roles",
  "common.addRole": "Add Role",
  "common.refuse": "Refuse",
  "common.refund": "Refund",
  "common.goToRefund": "Go to refund",
  "common.remove": "Remove",
  "common.cancel": "Cancel",
  "common.viewSales": "View Sales",
  "common.viewProducts": "View Products",
  "common.updateDelivery": "Update delivery",
  "common.set_default": "Set Default",
  "common.buy_member_reward_as_score": "Purchase membership reward as score",
  "common.PAID": "PAID",
  "common.total_withdraw_success": "Total Withdraw Success",
  "common.total_withdraw_waiting": "Total Withdraw Waiting",
  "common.total_Recharge_success": "Total Recharge Success",
  "common.total_income": "Total Income",
  "common.total_expend": "Total Expend",
  "common.note_create_task_configuration":
    "To enable tasks, please provide a value in the respective fields. Leaving a field empty will result in the task being disabled.",
  "common.invite_friends": "Invite friends",
  "common.visit_store": "Visit store",
  "common.watch_video": "Watch video",
  "common.answer_question": "Answer question",
  "common.read_news": "Read news",
  "common.visit_flash_sale": "Visit flash sale",
  "common.promote_links": "Share with friends",
  "common.bubble": "Bubble release",
  "common.totalPeopleJoinTask": "Total people join task",
  "common.totalScoreUserGet": "Total score user get",
  "common.totalAmount": "Total amount",
  "common.export": "Export",
  "common.variation": "Variation",
  "common.update_image": "Update Image",
  "common.save": "Save",
  "common.add_parent_variation": "Add Parent Variation",
  "common.add_child_variation": "Add Child Variation",
  "common.add_variation": "Add Variation",
  "common.edit_variation": "Edit Variation",
  "common.edit_name": "Edit Name",
  "common.edit_value": "Edit Value",
  "common.approvalFailed": "Approval Failed!",
  "common.rejectWithdraw": "Reject Withdraw",
  "common.hindi": "Hindi",
  "common.english": "English",
};
