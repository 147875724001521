import React, { useEffect, useRef } from "react";
import { AppProvider, CenterLayout, useAppContext } from "@qsh/center";
import { authMenusApi } from "@/services/api";
import { createPage } from "@qsh/base";
import { useTranslation } from "react-i18next";
import { getCurrentUser } from "@qsh/center/user";
import { useLocation } from "react-router-dom";

export default createPage(AppProvider, () => {
  const [t, i18n] = useTranslation();
  const { refreshMenus } = useAppContext();
  const location = useLocation();
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    refreshMenus();
  }, [i18n.language]);

  return (
    <CenterLayout
      app_name={t("common:common.adminManagement")}
      logout={t("common:common.logout")}
      requestMenu={async () => {
        if (getCurrentUser() || !["/", ""].includes(location.pathname)) {
          const res = await authMenusApi({});
          return res.items as any;
        }

        return [];
      }}
      changeLanguage={(lng) => i18n.changeLanguage(lng)}
      locale={i18n.language}
    />
  );
});
