// Generated by api.js
// DO NOT EDIT

import {createApi} from './request'

export const adminImageListApi = createApi<AdminImageListRequest,AdminImageListResponse>('/admin/admin_image/list')
export const adminVideoListApi = createApi<AdminVideoListRequest,AdminVideoListResponse>('/admin/admin_video/list')
export const authCaptchaApi = createApi<AuthCaptchaRequest,AuthCaptchaResponse>('/admin/auth/captcha')
export const authLoginApi = createApi<AuthLoginRequest,AuthLoginResponse>('/admin/auth/login')
export const authMenusApi = createApi<AuthMenusRequest,AuthMenusResponse>('/admin/auth/menus')
export const centerApiAllApi = createApi<CenterApiAllRequest,CenterApiAllResponse>('/admin/center/api/all')
export const centerApiCreateApi = createApi<CenterApiCreateRequest,CenterApiCreateResponse>('/admin/center/api/create')
export const centerApiListApi = createApi<CenterApiListRequest,CenterApiListResponse>('/admin/center/api/list')
export const centerApiSwitchStatusApi = createApi<CenterApiSwitchStatusRequest,CenterApiSwitchStatusResponse>('/admin/center/api/switch/status')
export const centerApiUpdateApi = createApi<CenterApiUpdateRequest,CenterApiUpdateResponse>('/admin/center/api/update')
export const centerMenuAllApi = createApi<CenterMenuAllRequest,CenterMenuAllResponse>('/admin/center/menu/all')
export const centerMenuBindApiAllApi = createApi<CenterMenuBindApiAllRequest,CenterMenuBindApiAllResponse>('/admin/center/menu/bind/api/all')
export const centerMenuBindApiCreateApi = createApi<CenterMenuBindApiCreateRequest,CenterMenuBindApiCreateResponse>('/admin/center/menu/bind/api/create')
export const centerMenuBindApiDeleteApi = createApi<CenterMenuBindApiDeleteRequest,CenterMenuBindApiDeleteResponse>('/admin/center/menu/bind/api/delete')
export const centerMenuBindApiListApi = createApi<CenterMenuBindApiListRequest,CenterMenuBindApiListResponse>('/admin/center/menu/bind/api/list')
export const centerMenuCreateApi = createApi<CenterMenuCreateRequest,CenterMenuCreateResponse>('/admin/center/menu/create')
export const centerMenuDetailApi = createApi<CenterMenuDetailRequest,CenterMenuDetailResponse>('/admin/center/menu/detail')
export const centerMenuSwitchStatusApi = createApi<CenterMenuSwitchStatusRequest,CenterMenuSwitchStatusResponse>('/admin/center/menu/switch/status')
export const centerMenuUpdateApi = createApi<CenterMenuUpdateRequest,CenterMenuUpdateResponse>('/admin/center/menu/update')
export const centerRoleAllApi = createApi<CenterRoleAllRequest,CenterRoleAllResponse>('/admin/center/role/all')
export const centerRoleBindApiListApi = createApi<CenterRoleBindApiListRequest,CenterRoleBindApiListResponse>('/admin/center/role/bind/api/list')
export const centerRoleBindApiUpdateApi = createApi<CenterRoleBindApiUpdateRequest,CenterRoleBindApiUpdateResponse>('/admin/center/role/bind/api/update')
export const centerRoleBindMenuListApi = createApi<CenterRoleBindMenuListRequest,CenterRoleBindMenuListResponse>('/admin/center/role/bind/menu/list')
export const centerRoleBindMenuUpdateApi = createApi<CenterRoleBindMenuUpdateRequest,CenterRoleBindMenuUpdateResponse>('/admin/center/role/bind/menu/update')
export const centerRoleCreateApi = createApi<CenterRoleCreateRequest,CenterRoleCreateResponse>('/admin/center/role/create')
export const centerRoleListApi = createApi<CenterRoleListRequest,CenterRoleListResponse>('/admin/center/role/list')
export const centerRoleSwitchStatusApi = createApi<CenterRoleSwitchStatusRequest,CenterRoleSwitchStatusResponse>('/admin/center/role/switch/status')
export const centerRoleUpdateApi = createApi<CenterRoleUpdateRequest,CenterRoleUpdateResponse>('/admin/center/role/update')
export const centerUserCreateApi = createApi<CenterUserCreateRequest,CenterUserCreateResponse>('/admin/center/user/create')
export const centerUserListApi = createApi<CenterUserListRequest,CenterUserListResponse>('/admin/center/user/list')
export const centerUserModifyPasswordApi = createApi<CenterUserModifyPasswordRequest,CenterUserModifyPasswordResponse>('/admin/center/user/modify/password')
export const centerUserRoleBindApi = createApi<CenterUserRoleBindRequest,CenterUserRoleBindResponse>('/admin/center/user/role/bind')
export const centerUserRoleListApi = createApi<CenterUserRoleListRequest,CenterUserRoleListResponse>('/admin/center/user/role/list')
export const centerUserRoleUnbindApi = createApi<CenterUserRoleUnbindRequest,CenterUserRoleUnbindResponse>('/admin/center/user/role/unbind')
export const centerUserSwitchStatusApi = createApi<CenterUserSwitchStatusRequest,CenterUserSwitchStatusResponse>('/admin/center/user/switch/status')
export const centerUserUpdateApi = createApi<CenterUserUpdateRequest,CenterUserUpdateResponse>('/admin/center/user/update')
export const defaultEContractCreateApi = createApi<DefaultEContractCreateRequest,DefaultEContractCreateResponse>('/admin/default_e_contract/create')
export const defaultEContractDetailApi = createApi<DefaultEContractDetailRequest,DefaultEContractDetailResponse>('/admin/default_e_contract/detail')
export const defaultEContractListApi = createApi<DefaultEContractListRequest,DefaultEContractListResponse>('/admin/default_e_contract/list')
export const eAgentContractClubPaymentCreateApi = createApi<EAgentContractClubPaymentCreateRequest,EAgentContractClubPaymentCreateResponse>('/admin/e_agent_contract/club_payment/create')
export const eAgentContractClubPaymentListApi = createApi<EAgentContractClubPaymentListRequest,EAgentContractClubPaymentListResponse>('/admin/e_agent_contract/club_payment/list')
export const eAgentContractClubPaymentUpdateApi = createApi<EAgentContractClubPaymentUpdateRequest,EAgentContractClubPaymentUpdateResponse>('/admin/e_agent_contract/club_payment/update')
export const eAgentContractCompletedApi = createApi<EAgentContractCompletedRequest,EAgentContractCompletedResponse>('/admin/e_agent_contract/completed')
export const eAgentContractCreateApi = createApi<EAgentContractCreateRequest,EAgentContractCreateResponse>('/admin/e_agent_contract/create')
export const eAgentContractDeleteApi = createApi<EAgentContractDeleteRequest,EAgentContractDeleteResponse>('/admin/e_agent_contract/delete')
export const eAgentContractListApi = createApi<EAgentContractListRequest,EAgentContractListResponse>('/admin/e_agent_contract/list')
export const eAgentContractReceivedPaymentApi = createApi<EAgentContractReceivedPaymentRequest,EAgentContractReceivedPaymentResponse>('/admin/e_agent_contract/received_payment')
export const eCompanyContractCreateApi = createApi<ECompanyContractCreateRequest,ECompanyContractCreateResponse>('/admin/e_company_contract/create')
export const eCompanyContractListApi = createApi<ECompanyContractListRequest,ECompanyContractListResponse>('/admin/e_company_contract/list')
export const eContractClubCreateApi = createApi<EContractClubCreateRequest,EContractClubCreateResponse>('/admin/e_contract/club/create')
export const eContractClubListApi = createApi<EContractClubListRequest,EContractClubListResponse>('/admin/e_contract/club/list')
export const eContractClubUdateStatusApi = createApi<EContractClubUpdateStatusRequest,EContractClubUpdateStatusResponse>('/admin/e_contract/club/udate_status')
export const eContractClubUpdateApi = createApi<EContractClubUpdateRequest,EContractClubUpdateResponse>('/admin/e_contract/club/update')
export const eContractClubPaymentCreateApi = createApi<EContractClubPaymentCreateRequest,EContractClubPaymentCreateResponse>('/admin/e_contract/club_payment/create')
export const eContractClubPaymentListApi = createApi<EContractClubPaymentListRequest,EContractClubPaymentListResponse>('/admin/e_contract/club_payment/list')
export const eContractClubPaymentUpdateApi = createApi<EContractClubPaymentUpdateRequest,EContractClubPaymentUpdateResponse>('/admin/e_contract/club_payment/update')
export const eContractCompletedApi = createApi<EContractCompletedRequest,EContractCompletedResponse>('/admin/e_contract/completed')
export const eContractCreateApi = createApi<EContractCreateRequest,EContractCreateResponse>('/admin/e_contract/create')
export const eContractDeleteApi = createApi<EContractDeleteRequest,EContractDeleteResponse>('/admin/e_contract/delete')
export const eContractDetailApi = createApi<EContractDetailRequest,EContractDetailResponse>('/admin/e_contract/detail')
export const eContractFindPartyApi = createApi<EContractFindPartyRequest,EContractFindPartyResponse>('/admin/e_contract/find_party')
export const eContractListApi = createApi<EContractListRequest,EContractListResponse>('/admin/e_contract/list')
export const eContractReceivedPaymentApi = createApi<EContractReceivedPaymentRequest,EContractReceivedPaymentResponse>('/admin/e_contract/received_payment')
export const eContractUpdateApi = createApi<EContractUpdateRequest,EContractUpdateResponse>('/admin/e_contract/update')
export const eGuaranteeContractClubPaymentCreateApi = createApi<EGuaranteeContractClubPaymentCreateRequest,EGuaranteeContractClubPaymentCreateResponse>('/admin/e_guarantee_contract/club_payment/create')
export const eGuaranteeContractClubPaymentListApi = createApi<EGuaranteeContractClubPaymentListRequest,EGuaranteeContractClubPaymentListResponse>('/admin/e_guarantee_contract/club_payment/list')
export const eGuaranteeContractClubPaymentUpdateApi = createApi<EGuaranteeContractClubPaymentUpdateRequest,EGuaranteeContractClubPaymentUpdateResponse>('/admin/e_guarantee_contract/club_payment/update')
export const eGuaranteeContractCompletedApi = createApi<EGuaranteeContractCompletedRequest,EGuaranteeContractCompletedResponse>('/admin/e_guarantee_contract/completed')
export const eGuaranteeContractCreateApi = createApi<EGuaranteeContractCreateRequest,EGuaranteeContractCreateResponse>('/admin/e_guarantee_contract/create')
export const eGuaranteeContractDeleteApi = createApi<EGuaranteeContractDeleteRequest,EGuaranteeContractDeleteResponse>('/admin/e_guarantee_contract/delete')
export const eGuaranteeContractListApi = createApi<EGuaranteeContractListRequest,EGuaranteeContractListResponse>('/admin/e_guarantee_contract/list')
export const eGuaranteeContractReceivedPaymentApi = createApi<EGuaranteeContractReceivedPaymentRequest,EGuaranteeContractReceivedPaymentResponse>('/admin/e_guarantee_contract/received_payment')
export const eIntripsContractCompletedApi = createApi<EInTripsContractCompletedRequest,EInTripsContractCompletedResponse>('/admin/e_intrips_contract/completed')
export const eIntripsContractCreateApi = createApi<EInTripsContractCreateRequest,EInTripsContractCreateResponse>('/admin/e_intrips_contract/create')
export const eIntripsContractDeleteApi = createApi<EInTripsContractDeleteRequest,EInTripsContractDeleteResponse>('/admin/e_intrips_contract/delete')
export const eIntripsContractListApi = createApi<EInTripsContractListRequest,EInTripsContractListResponse>('/admin/e_intrips_contract/list')
export const eInvestContractClubPaymentCreateApi = createApi<EInvestContractClubPaymentCreateRequest,EInvestContractClubPaymentCreateResponse>('/admin/e_invest_contract/club_payment/create')
export const eInvestContractClubPaymentListApi = createApi<EInvestContractClubPaymentListRequest,EInvestContractClubPaymentListResponse>('/admin/e_invest_contract/club_payment/list')
export const eInvestContractClubPaymentUpdateApi = createApi<EInvestContractClubPaymentUpdateRequest,EInvestContractClubPaymentUpdateResponse>('/admin/e_invest_contract/club_payment/update')
export const eInvestContractCompletedApi = createApi<EInvestContractCompletedRequest,EInvestContractCompletedResponse>('/admin/e_invest_contract/completed')
export const eInvestContractCreateApi = createApi<EInvestContractCreateRequest,EInvestContractCreateResponse>('/admin/e_invest_contract/create')
export const eInvestContractDeleteApi = createApi<EInvestContractDeleteRequest,EInvestContractDeleteResponse>('/admin/e_invest_contract/delete')
export const eInvestContractListApi = createApi<EInvestContractListRequest,EInvestContractListResponse>('/admin/e_invest_contract/list')
export const eInvestContractListIndiaApi = createApi<EInvestContractListRequest,EInvestContractListResponse>('/admin/e_invest_contract/list_india')
export const eInvestContractReceivedPaymentApi = createApi<EInvestContractReceivedPaymentRequest,EInvestContractReceivedPaymentResponse>('/admin/e_invest_contract/received_payment')
export const userDetailApi = createApi<UserDetailRequest,UserDetailResponse>('/admin/user/detail')
export const userListApi = createApi<UserListRequest,UserListResponse>('/admin/user/list')
export const userUpdateStatusApi = createApi<UserUpdateStatusRequest,UserUpdateStatusResponse>('/admin/user/update_status')

export type AdminImageListItem = {
  http_url: string;
  id: number;
  name: string;
}

export type AdminImageListRequest = {
  // 名字
  name: string;
  // 页码
  page: number;
}

export type AdminImageListResponse = {
  items: AdminImageListItem[];
  // 总数
  total: number;
}

export type AdminVideoListItem = {
  // http_url 视频地址
  http_url: string;
  // id 视频id
  id: number;
  // name 视频名称
  name: string;
}

export type AdminVideoListRequest = {
  // name 视频名称
  name: string;
  // page 页码
  page: number;
  // page_size 每页数量
  page_size: number;
}

export type AdminVideoListResponse = {
  items: AdminVideoListItem[];
  // total 总数
  total: number;
}

export type AuthCaptchaRequest = {

}

export type AuthCaptchaResponse = {
  // 验证码ID
  captcha_id: string;
  // 验证码图片
  captcha_image: string;
}

export type AuthLoginRequest = {
  // 验证码ID
  captcha_id: string;
  // 密码
  password: string;
  // 用户名
  username: string;
  // 验证码
  verify_code: string;
}

export type AuthLoginResponse = {
  // 昵称
  nickname: string;
  // token
  token: string;
  // 用户id
  user_id: string;
  // 用户名
  username: string;
}

export type AuthMenusRequest = {

}

export type AuthMenusResponse = {
  items: CenterMenuInfo[];
}

export type AuthUser = {
  user_id: string;
}

export type CenterApiAllItem = {
  id: number;
  // api名称
  name: string;
  // api路径
  path: string;
}

export type CenterApiAllRequest = {
  // 状态
  status: string;
}

export type CenterApiAllResponse = {
  items: CenterApiAllItem[];
}

export type CenterApiCreateRequest = {
  // api名称
  name: string;
  // api路径
  path: string;
}

export type CenterApiCreateResponse = {

}

export type CenterApiInfo = {
  // 创建时间
  created_at: string;
  id: number;
  // api名称
  name: string;
  // api路径
  path: string;
  // 状态
  status: string;
}

export type CenterApiListRequest = {
  // api名称
  name: string;
  page: number;
}

export type CenterApiListResponse = {
  items: CenterApiInfo[];
  total: number;
}

export type CenterApiSwitchStatusRequest = {
  id: number;
  status: string;
}

export type CenterApiSwitchStatusResponse = {

}

export type CenterApiUpdateRequest = {
  id: number;
  // api名称
  name: string;
  // api路径
  path: string;
}

export type CenterApiUpdateResponse = {

}

export type CenterMenuAllRequest = {

}

export type CenterMenuAllResponse = {
  items: CenterMenuInfo[];
}

export type CenterMenuBindApiAllRequest = {

}

export type CenterMenuBindApiAllResponse = {
  items: CenterMenuBindApiInfo[];
}

export type CenterMenuBindApiCreateRequest = {
  api_ids: number[];
  menu_id: number;
}

export type CenterMenuBindApiCreateResponse = {

}

export type CenterMenuBindApiDeleteRequest = {
  api_id: number;
  menu_id: number;
}

export type CenterMenuBindApiDeleteResponse = {

}

export type CenterMenuBindApiInfo = {
  api_id: number;
  create_at: string;
  id: number;
  menu_id: number;
  name: string;
  path: string;
}

export type CenterMenuBindApiListRequest = {
  menu_id: number;
}

export type CenterMenuBindApiListResponse = {
  items: CenterMenuBindApiInfo[];
}

export type CenterMenuCreateRequest = {
  app_id: string;
  icon: string;
  name_json: modelLang;
  path: string;
  pid: number;
  sort_num: number;
}

export type CenterMenuCreateResponse = {

}

export type CenterMenuDetailRequest = {
  id: number;
}

export type CenterMenuDetailResponse = {
  created_at: string;
  icon: string;
  id: number;
  name: string;
  name_json: modelLang;
  path: string;
  pid: number;
  sort_num: number;
  status: string;
  updated_at: string;
}

export type CenterMenuInfo = {
  created_at: string;
  icon: string;
  id: number;
  name: string;
  name_json: modelLang;
  path: string;
  pid: number;
  sort_num: number;
  status: string;
  updated_at: string;
}

export type CenterMenuSwitchStatusRequest = {
  id: number;
  status: string;
}

export type CenterMenuSwitchStatusResponse = {

}

export type CenterMenuUpdateRequest = {
  icon: string;
  id: number;
  name_json: modelLang;
  path: string;
  pid: number;
  sort_num: number;
}

export type CenterMenuUpdateResponse = {

}

export type CenterRoleAllItem = {
  id: number;
  // 角色名称
  name: string;
}

export type CenterRoleAllRequest = {

}

export type CenterRoleAllResponse = {
  items: CenterRoleAllItem[];
}

export type CenterRoleBindApiListRequest = {
  // 角色id
  role_id: number;
}

export type CenterRoleBindApiListResponse = {
  api_id_list: number[];
}

export type CenterRoleBindApiUpdateRequest = {
  api_id_list: number[];
  // 角色id
  role_id: number;
}

export type CenterRoleBindApiUpdateResponse = {

}

export type CenterRoleBindMenuListRequest = {
  // 角色id
  role_id: number;
}

export type CenterRoleBindMenuListResponse = {
  menu_id_list: number[];
}

export type CenterRoleBindMenuUpdateRequest = {
  menu_id_list: number[];
  // 角色id
  role_id: number;
}

export type CenterRoleBindMenuUpdateResponse = {

}

export type CenterRoleCreateRequest = {
  // 角色名称
  name: string;
}

export type CenterRoleCreateResponse = {

}

export type CenterRoleInfo = {
  // 创建时间
  created_at: string;
  id: number;
  // 是否为超级管理员
  is_super: string;
  // 角色名称
  name: string;
  // 状态
  status: string;
}

export type CenterRoleListRequest = {
  page: number;
}

export type CenterRoleListResponse = {
  items: CenterRoleInfo[];
  total: number;
}

export type CenterRoleSwitchStatusRequest = {
  id: number;
  // 状态
  status: string;
}

export type CenterRoleSwitchStatusResponse = {

}

export type CenterRoleUpdateRequest = {
  id: number;
  // 角色名称
  name: string;
}

export type CenterRoleUpdateResponse = {

}

export type CenterUserCreateRequest = {
  nickname: string;
  password: string;
  remark: string;
  user_type: string;
  username: string;
}

export type CenterUserCreateResponse = {

}

export type CenterUserListRequest = {
  page: number;
  user_id: string;
  username: string;
}

export type CenterUserListResponse = {
  items: UserInfo[];
  total: number;
}

export type CenterUserModifyPasswordRequest = {
  id: number;
  password: string;
}

export type CenterUserModifyPasswordResponse = {

}

export type CenterUserRole = {
  // 创建时间
  created_at: string;
  // 角色ID
  id: number;
  // 是否未超级管理员
  is_super: string;
  // 角色名称
  name: string;
  // 角色状态
  status: string;
}

export type CenterUserRoleBindRequest = {
  role_id: number;
  user_id: string;
}

export type CenterUserRoleBindResponse = {

}

export type CenterUserRoleListRequest = {
  user_id: string;
}

export type CenterUserRoleListResponse = {
  items: CenterUserRole[];
}

export type CenterUserRoleUnbindRequest = {
  role_id: number;
  user_id: string;
}

export type CenterUserRoleUnbindResponse = {

}

export type CenterUserSwitchStatusRequest = {
  id: number;
  status: string;
}

export type CenterUserSwitchStatusResponse = {

}

export type CenterUserUpdateRequest = {
  id: number;
  nickname: string;
  remark: string;
  user_type: string;
}

export type CenterUserUpdateResponse = {

}

export type DefaultEContractCreateRequest = {
  // 投资金额
  amount: string;
  // 授权产品
  authorized_products: string;
  // 默认类型: CHINESE_RECHARGE_TWO_PARTY_BANK:中文充值两方银行 ENGLISH_RECHARGE_TWO_PARTY_USDT:英文充值两方USDT CHINESE_COMPANY_THREE_PARTY:中文公司三方 CHINESE_AGENT_TWO_PARTY:中文代理两方 CHINESE_APP_INVEST_FOUR_PARTY:中文APP投资四方 INDIA_INVEST_HINDI_THREE_PARTY:印度投资印地语三方 INDIA_INVEST_ENGLISH_THREE_PARTY:印度投资英文三方
  default_type: string;
  party_a: modelPartyConfigAttrType;
  // 甲方签名
  party_a_signature: string;
  party_b: modelPartyConfigAttrType;
  // 乙方签名
  party_b_signature: string;
  party_c: modelPartyConfigAttrType;
  // 丙方签名
  party_c_signature: string;
  party_d: modelPartyConfigAttrType;
  // 丁方签名
  party_d_signature: string;
}

export type DefaultEContractCreateResponse = {

}

export type DefaultEContractDetailRequest = {
  // 默认类型: CHINESE_RECHARGE_TWO_PARTY_BANK:中文充值两方银行 ENGLISH_RECHARGE_TWO_PARTY_USDT:英文充值两方USDT CHINESE_COMPANY_THREE_PARTY:中文公司三方 CHINESE_AGENT_TWO_PARTY:中文代理两方 CHINESE_APP_INVEST_FOUR_PARTY:中文APP投资四方 INDIA_INVEST_HINDI_THREE_PARTY:印度投资印地语三方 INDIA_INVEST_ENGLISH_THREE_PARTY:印度投资英文三方
  default_type: string;
}

export type DefaultEContractDetailResponse = {
  // 投资金额
  amount: string;
  // 授权产品
  authorized_products: string;
  // 合同语言 ENGLISH:英文 CHINESE:中文 HINDI:印地语
  contract_language: string;
  // 创建时间
  created_at: string;
  // 默认类型: CHINESE_RECHARGE_TWO_PARTY_BANK:中文充值两方银行 ENGLISH_RECHARGE_TWO_PARTY_USDT:英文充值两方USDT CHINESE_COMPANY_THREE_PARTY:中文公司三方 CHINESE_AGENT_TWO_PARTY:中文代理两方 CHINESE_APP_INVEST_FOUR_PARTY:中文APP投资四方 INDIA_INVEST_HINDI_THREE_PARTY:印度投资印地语三方 INDIA_INVEST_ENGLISH_THREE_PARTY:印度投资英文三方
  default_type: string;
  // 合同ID
  id: number;
  party_a: modelPartyConfigAttrType;
  // 甲方签名
  party_a_signature: string;
  party_b: modelPartyConfigAttrType;
  // 乙方签名
  party_b_signature: string;
  party_c: modelPartyConfigAttrType;
  // 丙方签名
  party_c_signature: string;
  party_d: modelPartyConfigAttrType;
  // 丁方签名
  party_d_signature: string;
  // 更新时间
  updated_at: string;
}

export type DefaultEContractListItem = {

}

export type DefaultEContractListRequest = {
  // 默认类型: CHINESE_RECHARGE_TWO_PARTY_BANK:中文充值两方银行 ENGLISH_RECHARGE_TWO_PARTY_USDT:英文充值两方USDT CHINESE_COMPANY_THREE_PARTY:中文公司三方 CHINESE_AGENT_TWO_PARTY:中文代理两方 CHINESE_APP_INVEST_FOUR_PARTY:中文APP投资四方 INDIA_INVEST_HINDI_THREE_PARTY:印度投资印地语三方 INDIA_INVEST_ENGLISH_THREE_PARTY:印度投资英文三方
  default_type: string;
  // 页码
  page: number;
  // 每页数量
  page_size: number;
}

export type DefaultEContractListResponse = {
  items: DefaultEContractListItem[];
  // 总数
  total: number;
}

export type EAgentContractClubPaymentCreateRequest = {
  // 合同Number
  contract_number: string;
  // 支付金额
  payment_amount: string;
  // 支付时间
  payment_date: string;
  // 备注
  remark: string;
}

export type EAgentContractClubPaymentCreateResponse = {

}

export type EAgentContractClubPaymentListItem = {
  // 俱乐部ID
  club_id: number;
  // 合同ID
  contract_id: number;
  // 创建时间
  created_at: string;
  // 俱乐部支付ID
  id: number;
  // 支付金额
  payment_amount: string;
  // 支付时间
  payment_date: string;
  // 备注
  remark: string;
  // 更新时间
  updated_at: string;
}

export type EAgentContractClubPaymentListRequest = {
  // 合同Number
  contract_number: string;
  // 页码
  page: number;
  // 每页数量
  page_size: number;
}

export type EAgentContractClubPaymentListResponse = {
  items: EAgentContractClubPaymentListItem[];
  // 总数
  total: number;
}

export type EAgentContractClubPaymentUpdateRequest = {
  // 俱乐部支付ID
  id: number;
  // 支付金额
  payment_amount: string;
  // 支付时间
  payment_date: string;
  // 备注
  remark: string;
}

export type EAgentContractClubPaymentUpdateResponse = {

}

export type EAgentContractCompletedRequest = {
  // 合同Number
  contract_number: string;
}

export type EAgentContractCompletedResponse = {

}

export type EAgentContractCreateRequest = {
  // 授权区域
  authorized_area: string;
  // 授权产品
  authorized_products: string;
  // 城市
  city: string;
  // 俱乐部ID
  club_id: number;
  contact_method: modelContactMethodType;
  // 合同结束日期
  contract_end_date: string;
  // 合同语言 ENGLISH:英文 CHINESE:中文
  contract_language: string;
  // 合同开始日期
  contract_start_date: string;
  // 国家
  country: string;
  // 负责人
  head: string;
  party_a: modelPartyConfigAttrType;
  // 甲方签名
  party_a_signature: string;
  // 甲方签名日期
  party_a_signature_date: string;
  party_b: modelPartyConfigAttrType;
  // 乙方签名
  party_b_signature: string;
  // 乙方签名日期
  party_b_signature_date: string;
  // 支付方式 USDT:USDT BANK:银行卡
  payment_type: string;
  // 省份
  province: string;
  // 参考
  reference: string;
}

export type EAgentContractCreateResponse = {

}

export type EAgentContractDeleteRequest = {
  // 合同Number
  contract_number: string;
}

export type EAgentContractDeleteResponse = {

}

export type EAgentContractListItem = {
  // 授权区域
  authorized_area: string;
  // 授权产品
  authorized_products: string;
  // 城市
  city: string;
  // 俱乐部ID
  club_id: number;
  // 俱乐部名称
  club_name: string;
  contact_method: modelContactMethodType;
  // 合同结束日期
  contract_end_date: string;
  // 合同语言 ENGLISH:英文 CHINESE:中文
  contract_language: string;
  // 合同Number
  contract_number: string;
  // 合同开始日期
  contract_start_date: string;
  // 国家
  country: string;
  // 创建时间
  created_at: string;
  // 负责人
  head: string;
  party_a: modelPartyConfigAttrType;
  // 甲方签名
  party_a_signature: string;
  // 甲方签名日期
  party_a_signature_date: string;
  party_b: modelPartyConfigAttrType;
  // 乙方签名
  party_b_signature: string;
  // 乙方签名日期
  party_b_signature_date: string;
  // 支付到账日期
  payment_received_date: string;
  // 支付状态
  payment_status: string;
  // PaymentType 支付方式 USDT:USDT BANK:银行卡
  payment_type: string;
  // 省份
  province: string;
  // 参考
  reference: string;
  // 状态 PENDING:待审核 SIGNED:已签署 FINISHED:已完成
  status: string;
  // 更新时间
  updated_at: string;
}

export type EAgentContractListRequest = {
  // City 城市
  city: string;
  // ClubID 俱乐部ID
  club_id: number;
  // ContactMethod 联系方式
  contact_method: string;
  // 合同语言 ENGLISH:英文 CHINESE:中文
  contract_language: string;
  // 合同Number
  contract_number: string;
  // Country 国家
  country: string;
  created_at: string[];
  // Head 负责人
  head: string;
  // 页码
  page: number;
  // 每页数量
  page_size: number;
  // Party
  party: string;
  payment_received_date: string[];
  // payment_type 支付方式 USDT:USDT BANK:银行卡
  payment_type: string;
  // Province 省份
  province: string;
  // Reference 参考
  reference: string;
  // 状态
  status: string;
}

export type EAgentContractListResponse = {
  items: EAgentContractListItem[];
  // 总数
  total: number;
}

export type EAgentContractReceivedPaymentRequest = {
  // 合同Number
  contract_number: string;
  // 支付到账日期
  payment_received_date: string;
}

export type EAgentContractReceivedPaymentResponse = {

}

export type ECompanyContractCreateRequest = {
  amount: string;
  club_id: number;
  company: modelCompanyAttrType;
  contact_method: modelContactMethodType;
  head: string;
  party_a: modelPartyAAttrType;
  party_a_signature: string;
  party_b: modelPartyBAttrType;
  party_c: modelPartyCAttrType;
  party_c_signature: string;
  reference: string;
}

export type ECompanyContractCreateResponse = {

}

export type ECompanyContractListItem = {
  // 俱乐部ID
  club_id: number;
  // 俱乐部名称
  club_name: string;
  company: modelCompanyAttrType;
  contact_method: modelContactMethodType;
  // 合同Number
  contract_number: string;
  // 创建时间
  created_at: string;
  // 负责人
  head: string;
  // 丁方
  investment_amount: string;
  party_a: modelPartyAAttrType;
  party_b: modelPartyBAttrType;
  // 币种
  party_b_signature: string;
  party_c: modelPartyCAttrType;
  // 丙方签名
  party_c_signature: string;
  // 支付到账日期
  payment_received_date: string;
  // 支付状态
  payment_status: string;
  // 参考
  reference: string;
  // 状态 PENDING:待审核 SIGNED:已签署 FINISHED:已完成
  status: string;
  // 更新时间
  updated_at: string;
}

export type ECompanyContractListRequest = {
  // Amount 投资金额
  amount: string;
  // ClubID 俱乐部ID
  club_id: number;
  // ContactMethod 联系方式
  contact_method: string;
  // 合同Number
  contract_number: string;
  created_at: string[];
  // Head 负责人
  head: string;
  // 页码
  page: number;
  // 每页数量
  page_size: number;
  // Party
  party_c: string;
  payment_received_date: string[];
  // Reference 参考
  reference: string;
  // 状态
  status: string;
}

export type ECompanyContractListResponse = {
  items: ECompanyContractListItem[];
  // 总数
  total: number;
}

export type EContractClubCreateRequest = {
  // 俱乐部名称
  name: string;
  // 状态 ON:启用 OFF:禁用
  status: string;
}

export type EContractClubCreateResponse = {

}

export type EContractClubListItem = {
  // 创建时间
  created_at: string;
  // 俱乐部ID
  id: number;
  // 俱乐部名称
  name: string;
  // status 状态 ON:启用 OFF:禁用
  status: string;
  // 更新时间
  updated_at: string;
}

export type EContractClubListRequest = {

}

export type EContractClubListResponse = {
  items: EContractClubListItem[];
  // 总数
  total: number;
}

export type EContractClubPaymentCreateRequest = {
  // 合同Number
  contract_number: string;
  // 支付金额
  payment_amount: string;
  // 支付时间
  payment_date: string;
  // 备注
  remark: string;
}

export type EContractClubPaymentCreateResponse = {

}

export type EContractClubPaymentListItem = {
  // 俱乐部ID
  club_id: number;
  // 合同ID
  contract_id: number;
  // 创建时间
  created_at: string;
  // 俱乐部支付ID
  id: number;
  // 支付金额
  payment_amount: string;
  // 支付时间
  payment_date: string;
  // 备注
  remark: string;
  // 更新时间
  updated_at: string;
}

export type EContractClubPaymentListRequest = {
  // 合同Number
  contract_number: string;
  // 页码
  page: number;
  // 每页数量
  page_size: number;
}

export type EContractClubPaymentListResponse = {
  items: EContractClubPaymentListItem[];
  // 总数
  total: number;
}

export type EContractClubPaymentUpdateRequest = {
  // 俱乐部支付ID
  id: number;
  // 支付金额
  payment_amount: string;
  // 支付时间
  payment_date: string;
  // 备注
  remark: string;
}

export type EContractClubPaymentUpdateResponse = {

}

export type EContractClubUpdateRequest = {
  // 俱乐部ID
  id: number;
  // 俱乐部名称
  name: string;
}

export type EContractClubUpdateResponse = {

}

export type EContractClubUpdateStatusRequest = {
  // 俱乐部ID
  id: number;
  // 状态 ON:启用 OFF:禁用
  status: string;
}

export type EContractClubUpdateStatusResponse = {

}

export type EContractCompletedRequest = {
  // 合同Number
  contract_number: string;
}

export type EContractCompletedResponse = {

}

export type EContractCreateRequest = {
  // 城市
  city: string;
  // 俱乐部ID
  club_id: number;
  contact_method: modelContactMethodType;
  // 合同语言 ENGLISH:英文 CHINESE:中文
  contract_language: string;
  // 国家
  country: string;
  // 币种
  currency: string;
  // 周期选择
  cycle_selection: string;
  // 负责人
  head: string;
  // 投资金额
  investment_amount: string;
  party_a: modelPartyConfigAttrType;
  // 甲方签名
  party_a_signature: string;
  // 甲方签名日期
  party_a_signature_date: string;
  party_b: modelPartyConfigAttrType;
  // 乙方签名
  party_b_signature: string;
  // 乙方签名日期
  party_b_signature_date: string;
  party_c: modelPartyConfigAttrType;
  // 丁方
  party_d: string;
  // 乙方签名
  party_d_signature: string;
  // 乙方签名日期
  party_d_signature_date: string;
  // 支付方式 USDT:USDT BANK:银行卡
  payment_type: string;
  // 省份
  province: string;
  // 参考
  reference: string;
}

export type EContractCreateResponse = {

}

export type EContractDeleteRequest = {
  // 合同Number
  contract_number: string;
}

export type EContractDeleteResponse = {

}

export type EContractDetailRequest = {
  // 合同Number
  contract_number: string;
}

export type EContractDetailResponse = {
  // 合同语言 ENGLISH:英文 CHINESE:中文
  contract_language: string;
  // 合同Number
  contract_number: string;
  // 国家
  country: string;
  // 创建时间
  created_at: string;
  // 币种
  currency: string;
  // 周期选择
  cycle_selection: string;
  // 负责人
  head: string;
  // 投资金额
  investment_amount: string;
  party_a: modelPartyConfigAttrType;
  // 甲方签名
  party_a_signature: string;
  // 甲方签名日期
  party_a_signature_date: string;
  party_b: modelPartyConfigAttrType;
  // 乙方签名
  party_b_signature: string;
  // 乙方签名日期
  party_b_signature_date: string;
  party_c: modelPartyConfigAttrType;
  // 丙方签名
  party_c_signature: string;
  // 丙方签名日期
  party_c_signature_date: string;
  // 丁方
  party_d: string;
  // 丁方签名
  party_d_signature: string;
  // 丁方签名日期
  party_d_signature_date: string;
  // 省份
  province: string;
  // 状态 PENDING:待审核 SIGNED:已签署 FINISHED:已完成
  status: string;
  // 更新时间
  updated_at: string;
}

export type EContractFindPartyItem = {
  label: string;
  value: string;
}

export type EContractFindPartyRequest = {
  // keyword 关键字
  keyword: string;
}

export type EContractFindPartyResponse = {
  items: EContractFindPartyItem[];
}

export type EContractListItem = {
  // 城市
  city: string;
  // 俱乐部ID
  club_id: number;
  // 俱乐部名称
  club_name: string;
  contact_method: modelContactMethodType;
  // 合同语言 ENGLISH:英文 CHINESE:中文
  contract_language: string;
  // 合同Number
  contract_number: string;
  // 国家
  country: string;
  // 创建时间
  created_at: string;
  // 币种
  currency: string;
  // 周期选择
  cycle_selection: string;
  // 负责人
  head: string;
  // 投资金额
  investment_amount: string;
  party_a: modelPartyConfigAttrType;
  // 甲方签名
  party_a_signature: string;
  // 甲方签名日期
  party_a_signature_date: string;
  party_b: modelPartyConfigAttrType;
  // 乙方签名
  party_b_signature: string;
  // 乙方签名日期
  party_b_signature_date: string;
  party_c: modelPartyConfigAttrType;
  // 丙方签名
  party_c_signature: string;
  // 丙方签名日期
  party_c_signature_date: string;
  // 丁方
  party_d: string;
  // 丁方签名
  party_d_signature: string;
  // 丁方签名日期
  party_d_signature_date: string;
  // 支付到账日期
  payment_received_date: string;
  // 支付状态
  payment_status: string;
  // PaymentType 支付方式 USDT:USDT BANK:银行卡
  payment_type: string;
  // 省份
  province: string;
  // 参考
  reference: string;
  // 状态 PENDING:待审核 SIGNED:已签署 FINISHED:已完成
  status: string;
  // 更新时间
  updated_at: string;
}

export type EContractListRequest = {
  // City 城市
  city: string;
  // ClubID 俱乐部ID
  club_id: number;
  // ContactMethod 联系方式
  contact_method: string;
  // 合同语言 ENGLISH:英文 CHINESE:中文
  contract_language: string;
  // 合同Number
  contract_number: string;
  // Country 国家
  country: string;
  created_at: string[];
  // Head 负责人
  head: string;
  // InvestmentAmount 投资金额
  investment_amount: string;
  // 页码
  page: number;
  // 每页数量
  page_size: number;
  // Party
  party_c: string;
  payment_received_date: string[];
  // payment_type 支付方式 USDT:USDT BANK:银行卡
  payment_type: string;
  // Province 省份
  province: string;
  // Reference 参考
  reference: string;
  // 状态
  status: string;
}

export type EContractListResponse = {
  items: EContractListItem[];
  // 总数
  total: number;
}

export type EContractReceivedPaymentRequest = {
  // 合同Number
  contract_number: string;
  // 支付到账日期
  payment_received_date: string;
}

export type EContractReceivedPaymentResponse = {

}

export type EContractUpdateRequest = {
  // 合同语言 ENGLISH:英文 CHINESE:中文
  contract_language: string;
  // 合同Number
  contract_number: string;
  // 国家
  country: string;
  // 周期选择
  cycle_selection: string;
  // 负责人
  head: string;
  // 投资金额
  investment_amount: string;
  party_a: modelPartyConfigAttrType;
  // 甲方签名
  party_a_signature: string;
  // 甲方签名日期
  party_a_signature_date: string;
  party_b: modelPartyConfigAttrType;
  // 乙方签名
  party_b_signature: string;
  // 乙方签名日期
  party_b_signature_date: string;
  party_c: modelPartyConfigAttrType;
  // 丁方
  party_d: string;
  // 省份
  province: string;
}

export type EContractUpdateResponse = {

}

export type EGuaranteeContractClubPaymentCreateRequest = {
  // 合同Number
  contract_number: string;
  // 支付金额
  payment_amount: string;
  // 支付时间
  payment_date: string;
  // 备注
  remark: string;
}

export type EGuaranteeContractClubPaymentCreateResponse = {

}

export type EGuaranteeContractClubPaymentListItem = {
  // 俱乐部ID
  club_id: number;
  // 合同ID
  contract_id: number;
  // 创建时间
  created_at: string;
  // 俱乐部支付ID
  id: number;
  // 支付金额
  payment_amount: string;
  // 支付时间
  payment_date: string;
  // 备注
  remark: string;
  // 更新时间
  updated_at: string;
}

export type EGuaranteeContractClubPaymentListRequest = {
  // 合同Number
  contract_number: string;
  // 页码
  page: number;
  // 每页数量
  page_size: number;
}

export type EGuaranteeContractClubPaymentListResponse = {
  items: EGuaranteeContractClubPaymentListItem[];
  // 总数
  total: number;
}

export type EGuaranteeContractClubPaymentUpdateRequest = {
  // 俱乐部支付ID
  id: number;
  // 支付金额
  payment_amount: string;
  // 支付时间
  payment_date: string;
  // 备注
  remark: string;
}

export type EGuaranteeContractClubPaymentUpdateResponse = {

}

export type EGuaranteeContractCompletedRequest = {
  // 合同Number
  contract_number: string;
}

export type EGuaranteeContractCompletedResponse = {

}

export type EGuaranteeContractCreateRequest = {
  // 城市
  city: string;
  // 俱乐部ID
  club_id: number;
  contact_method: modelContactMethodType;
  // 合同语言 ENGLISH:英文 CHINESE:中文
  contract_language: string;
  // 国家
  country: string;
  // 币种
  currency: string;
  // 周期选择
  cycle_selection: string;
  // 周期选择名称
  cycle_selection_name: string;
  // 负责人
  head: string;
  // 投资金额
  investment_amount: string;
  party_a: modelPartyConfigAttrType;
  // 甲方签名
  party_a_signature: string;
  // 甲方签名日期
  party_a_signature_date: string;
  party_b: modelPartyConfigAttrType;
  // 乙方签名
  party_b_signature: string;
  // 乙方签名日期
  party_b_signature_date: string;
  party_c: modelPartyConfigAttrType;
  // 丙方签名
  party_c_signature: string;
  // 丙方签名日期
  party_c_signature_date: string;
  party_d: modelPartyConfigAttrType;
  // 丁方签名
  party_d_signature: string;
  // 丁方签名日期
  party_d_signature_date: string;
  // 支付方式 USDT:USDT BANK:银行卡
  payment_type: string;
  // 省份
  province: string;
  // 参考
  reference: string;
}

export type EGuaranteeContractCreateResponse = {

}

export type EGuaranteeContractDeleteRequest = {
  // 合同Number
  contract_number: string;
}

export type EGuaranteeContractDeleteResponse = {

}

export type EGuaranteeContractListItem = {
  // 城市
  city: string;
  // 俱乐部ID
  club_id: number;
  // 俱乐部名称
  club_name: string;
  contact_method: modelContactMethodType;
  // 合同语言 ENGLISH:英文 CHINESE:中文
  contract_language: string;
  // 合同Number
  contract_number: string;
  // 国家
  country: string;
  // 创建时间
  created_at: string;
  // 币种
  currency: string;
  // 周期选择
  cycle_selection: string;
  // 周期选择名称
  cycle_selection_name: string;
  // 负责人
  head: string;
  // 投资金额
  investment_amount: string;
  party_a: modelPartyConfigAttrType;
  // 甲方签名
  party_a_signature: string;
  // 甲方签名日期
  party_a_signature_date: string;
  party_b: modelPartyConfigAttrType;
  // 乙方签名
  party_b_signature: string;
  // 乙方签名日期
  party_b_signature_date: string;
  party_c: modelPartyConfigAttrType;
  // 丙方签名
  party_c_signature: string;
  // 丙方签名日期
  party_c_signature_date: string;
  party_d: modelPartyConfigAttrType;
  // 丁方签名
  party_d_signature: string;
  // 丁方签名日期
  party_d_signature_date: string;
  // 支付到账日期
  payment_received_date: string;
  // 支付状态
  payment_status: string;
  // PaymentType 支付方式 USDT:USDT BANK:银行卡
  payment_type: string;
  // 省份
  province: string;
  // 参考
  reference: string;
  // 状态 PENDING:待审核 SIGNED:已签署 FINISHED:已完成
  status: string;
  // 更新时间
  updated_at: string;
}

export type EGuaranteeContractListRequest = {
  // City 城市
  city: string;
  // ClubID 俱乐部ID
  club_id: number;
  // ContactMethod 联系方式
  contact_method: string;
  // 合同语言 ENGLISH:英文 CHINESE:中文
  contract_language: string;
  // 合同Number
  contract_number: string;
  // Country 国家
  country: string;
  created_at: string[];
  // Head 负责人
  head: string;
  // InvestmentAmount 投资金额
  investment_amount: string;
  // 页码
  page: number;
  // 每页数量
  page_size: number;
  // Party
  party: string;
  payment_received_date: string[];
  // payment_type 支付方式 USDT:USDT BANK:银行卡
  payment_type: string;
  // Province 省份
  province: string;
  // Reference 参考
  reference: string;
  // 状态
  status: string;
}

export type EGuaranteeContractListResponse = {
  items: EGuaranteeContractListItem[];
  // 总数
  total: number;
}

export type EGuaranteeContractReceivedPaymentRequest = {
  // 合同Number
  contract_number: string;
  // 支付到账日期
  payment_received_date: string;
}

export type EGuaranteeContractReceivedPaymentResponse = {

}

export type EInTripsContractCompletedRequest = {
  // 合同Number
  contract_number: string;
}

export type EInTripsContractCompletedResponse = {

}

export type EInTripsContractCreateRequest = {
  // 合同图片
  contract_image: string;
  // 合同级别
  contract_level: number;
  // 合同级别名称
  contract_level_name: string;
  // 旅行ID
  inTrips_id: string;
  // 参考
  reference: string;
  // 用户名
  username: string;
}

export type EInTripsContractCreateResponse = {

}

export type EInTripsContractDeleteRequest = {
  // 合同Number
  contract_number: string;
}

export type EInTripsContractDeleteResponse = {

}

export type EInTripsContractListItem = {
  // 合同级别名称
  contract_image: string;
  // 合同级别
  contract_level: number;
  // 合同图片
  contract_level_name: string;
  // 合同Number
  contract_number: string;
  // 创建时间
  created_at: string;
  // 旅行ID
  intrips_id: string;
  // 参考
  reference: string;
  // 签名
  signature: string;
  // 签名日期
  signature_date: string;
  // 状态
  status: string;
  // 更新时间
  updated_at: string;
  // 用户名
  username: string;
}

export type EInTripsContractListRequest = {
  // 合同Number
  contract_number: string;
  created_at: string[];
  // 页码
  page: number;
  // 每页数量
  page_size: number;
  // 状态
  status: string;
}

export type EInTripsContractListResponse = {
  items: EInTripsContractListItem[];
  // 总数
  total: number;
}

export type EInvestContractClubPaymentCreateRequest = {
  // 合同Number
  contract_number: string;
  // 支付金额
  payment_amount: string;
  // 支付时间
  payment_date: string;
  // 备注
  remark: string;
}

export type EInvestContractClubPaymentCreateResponse = {

}

export type EInvestContractClubPaymentListItem = {
  // 俱乐部ID
  club_id: number;
  // 合同ID
  contract_id: number;
  // 创建时间
  created_at: string;
  // 俱乐部支付ID
  id: number;
  // 支付金额
  payment_amount: string;
  // 支付时间
  payment_date: string;
  // 备注
  remark: string;
  // 更新时间
  updated_at: string;
}

export type EInvestContractClubPaymentListRequest = {
  // 合同Number
  contract_number: string;
  // 页码
  page: number;
  // 每页数量
  page_size: number;
}

export type EInvestContractClubPaymentListResponse = {
  items: EInvestContractClubPaymentListItem[];
  // 总数
  total: number;
}

export type EInvestContractClubPaymentUpdateRequest = {
  // 俱乐部支付ID
  id: number;
  // 支付金额
  payment_amount: string;
  // 支付时间
  payment_date: string;
  // 备注
  remark: string;
}

export type EInvestContractClubPaymentUpdateResponse = {

}

export type EInvestContractCompletedRequest = {
  // 合同Number
  contract_number: string;
}

export type EInvestContractCompletedResponse = {

}

export type EInvestContractCreateRequest = {
  // 城市
  city: string;
  // 俱乐部ID
  club_id: number;
  contact_method: modelContactMethodType;
  // 合同语言 ENGLISH:英文 CHINESE:中文
  contract_language: string;
  // 国家
  country: string;
  // 币种
  currency: string;
  // 周期选择
  cycle_selection: string;
  // 周期选择名称
  cycle_selection_name: string;
  // 负责人
  head: string;
  // 投资金额
  investment_amount: string;
  party_a: modelPartyConfigAttrType;
  // 甲方签名
  party_a_signature: string;
  // 甲方签名日期
  party_a_signature_date: string;
  party_b: modelPartyConfigAttrType;
  // 乙方签名
  party_b_signature: string;
  // 乙方签名日期
  party_b_signature_date: string;
  party_c: modelPartyConfigAttrType;
  // 丙方签名
  party_c_signature: string;
  // 丙方签名日期
  party_c_signature_date: string;
  party_d: modelPartyConfigAttrType;
  // 丁方签名
  party_d_signature: string;
  // 丁方签名日期
  party_d_signature_date: string;
  // 支付方式 USDT:USDT BANK:银行卡
  payment_type: string;
  // 省份
  province: string;
  // 参考
  reference: string;
}

export type EInvestContractCreateResponse = {

}

export type EInvestContractDeleteRequest = {
  // 合同Number
  contract_number: string;
}

export type EInvestContractDeleteResponse = {

}

export type EInvestContractListItem = {
  // 城市
  city: string;
  // 俱乐部ID
  club_id: number;
  // 俱乐部名称
  club_name: string;
  contact_method: modelContactMethodType;
  // 合同语言 ENGLISH:英文 CHINESE:中文
  contract_language: string;
  // 合同Number
  contract_number: string;
  // 国家
  country: string;
  // 创建时间
  created_at: string;
  // 币种
  currency: string;
  // 周期选择
  cycle_selection: string;
  // 周期选择名称
  cycle_selection_name: string;
  // 负责人
  head: string;
  // 投资金额
  investment_amount: string;
  party_a: modelPartyConfigAttrType;
  // 甲方签名
  party_a_signature: string;
  // 甲方签名日期
  party_a_signature_date: string;
  party_b: modelPartyConfigAttrType;
  // 乙方签名
  party_b_signature: string;
  // 乙方签名日期
  party_b_signature_date: string;
  party_c: modelPartyConfigAttrType;
  // 丙方签名
  party_c_signature: string;
  // 丙方签名日期
  party_c_signature_date: string;
  party_d: modelPartyConfigAttrType;
  // 丁方签名
  party_d_signature: string;
  // 丁方签名日期
  party_d_signature_date: string;
  // 支付到账日期
  payment_received_date: string;
  // 支付状态
  payment_status: string;
  // PaymentType 支付方式 USDT:USDT BANK:银行卡
  payment_type: string;
  // 省份
  province: string;
  // 参考
  reference: string;
  // 状态 PENDING:待审核 SIGNED:已签署 FINISHED:已完成
  status: string;
  // 更新时间
  updated_at: string;
}

export type EInvestContractListRequest = {
  // City 城市
  city: string;
  // ClubID 俱乐部ID
  club_id: number;
  // ContactMethod 联系方式
  contact_method: string;
  // 合同语言 ENGLISH:英文 CHINESE:中文
  contract_language: string;
  // 合同Number
  contract_number: string;
  // Country 国家
  country: string;
  created_at: string[];
  // Head 负责人
  head: string;
  // InvestmentAmount 投资金额
  investment_amount: string;
  // 页码
  page: number;
  // 每页数量
  page_size: number;
  // Party
  party: string;
  payment_received_date: string[];
  // payment_type 支付方式 USDT:USDT BANK:银行卡
  payment_type: string;
  // Province 省份
  province: string;
  // Reference 参考
  reference: string;
  // 状态
  status: string;
}

export type EInvestContractListResponse = {
  items: EInvestContractListItem[];
  // 总数
  total: number;
}

export type EInvestContractReceivedPaymentRequest = {
  // 合同Number
  contract_number: string;
  // 支付到账日期
  payment_received_date: string;
}

export type EInvestContractReceivedPaymentResponse = {

}

export type Empty = {

}

export type ErrorResponse = {
  code: string;
  detail: any;
  message: string;
}

export type UserDetailRequest = {
  // 用户id
  user_id: number;
}

export type UserDetailResponse = {
  user: UserListItem;
  // 用户id
  user_id: number;
}

export type UserInfo = {
  created_at: string;
  id: number;
  nickname: string;
  remark: string;
  status: string;
  updated_at: string;
  user_id: string;
  user_type: string;
  username: string;
}

export type UserListItem = {
  // 头像
  avatar: string;
  // 余额
  balance: string;
  // 创建时间
  created_at: string;
  // 是否开启用户等级定级
  enable_level_grade: string;
  // 免手续费提现额度
  free_fee_withdraw_amount: string;
  // 收益
  income: string;
  // 投资金额
  invest_amount: string;
  // 邀请码
  invite_code: string;
  // 是否有效
  is_valid: string;
  // 代理级别
  level: number;
  // 昵称
  nickname: string;
  // ParentNickname
  parent_nickname: string;
  // 父级id
  pid: number;
  // 积分
  point: string;
  // 状态:启用ON,禁用OFF
  status: string;
  // 更新时间
  updated_at: string;
  // 用户id
  user_id: number;
  // 用户名 Email
  username: string;
}

export type UserListRequest = {
  // 是否开启用户等级定级
  enable_level_grade: string;
  // 邀请码
  invite_code: string;
  // 是否有效
  is_valid: string;
  // 代理级别
  level: number;
  // 昵称
  nickname: string;
  // 页码 page
  page: number;
  // 每页数量 page_size
  page_size: number;
  // 父级id
  pid: number;
  // 状态:启用ON,禁用OFF
  status: string;
  // 用户id
  user_id: number;
  // 用户名
  username: string;
}

export type UserListResponse = {
  items: UserListItem[];
  // total 总数
  total: number;
}

export type UserUpdateStatusRequest = {
  // 状态:启用ON,禁用OFF
  status: string;
  // 用户id
  user_id: number;
}

export type UserUpdateStatusResponse = {

}

export type account_info = {
  // the account name of party
  account_name: string;
  // the account number of party
  account_number: string;
  // the bank name of party
  bank_name: string;
  // the branch name of party
  branch_name: string;
  // the ifsc code of party
  ifsc_code: string;
}

export type basic_info = {
  // the email of party
  email: string;
  // the login id of party
  login_id: string;
  // the name of party
  name: string;
  // the phone number of party
  phone_number: string;
  // the register number of party
  register_number: string;
}

export type int = {

}

export type modelCompanyAttrType = {
  // the type of company
  company_type: string;
  // the credit code of company
  credit_code: string;
  // the date of established of company
  date_of_established: string;
  // the name of party
  name: string;
  // the registered capital of company
  registered_capital: string;
  // the repesentative of company
  repesentative: string;
}

export type modelContactMethodType = {
  // the value of contact number
  contact: string;
  // the type of contact method => EMAIL,PHONE_NUMBER,WECHAT,WHATSAPP,LINE,FACEBOOK,INSTAGRAM
  method_name: string;
}

export type modelLang = {
  // english value
  en: string;
  // chinese value
  zh: string;
}

export type modelLink = {
  // the type of link => ARTICLE_DETAIL_PAGE,DEPOSIT,INVEST,INVITE_FRIEND, HELP_CENTER,TASK,LEARN,MY_TEAM,SERVICE
  type: string;
  // the value of link
  value: string;
}

export type modelPartyAAttrType = {
  // the email of party
  email: string;
  // the name of party
  name: string;
  // the register number of party
  register_number: string;
}

export type modelPartyBAttrType = {
  // the name of party
  name: string;
}

export type modelPartyCAttrType = {
  // the name of party
  name: string;
  // the register number of party
  register_number: string;
}

export type modelPartyConfigAttrType = {
  account_info: account_info;
  basic_info: basic_info;
}
