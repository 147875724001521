import {message, notification} from 'antd'
import {SortOrder} from 'antd/lib/table/interface'
import {getEnvironment} from '@/config'
import React from 'react'
import {RequestData} from '@ant-design/pro-components'
import {clearCurrentUser, getCurrentUser} from '@qsh/center/user'
import {getCurrentRouter} from '@qsh/center'

const failStatusMap: { [key: number]: string } = {
  504: 'Gateway timeout',
  404: 'The requested address does not exist'
}

export class NetworkError extends Error {
  code: string
  message: string
  status: number

  constructor(code: string, msg: string, status: number) {
    super(msg)
    this.code = code
    this.message = msg
    this.status = status
  }
}

// type = 1  'application/json'
// type = 2  upload file
export const createApi = <T, R>(path: string) => async (data: T, type = 1) => {
  const language = localStorage.getItem("locale");
  const res = await fetch(getEnvironment().gateway + path, {
    method: 'POST',
    headers: {
      ...(type == 1 && {'Content-Type': 'application/json'}),
      'Authorization': getCurrentUser()?.token || '',
      "Accept-Language": language || "zh",
    },
    ...(type == 1 && {body: JSON.stringify(data)}),
    ...(type == 2 && {body: data as FormData})
  })

  if (res.status === 200) {
    return await res.json() as R
  }

  if (res.status === 401) {
    clearCurrentUser()
    getCurrentRouter().push({
      path: '/login',
      replace: true
    })
    throw new NetworkError('401', 'Not logged in', 401)
  }

  if (res.status in failStatusMap) {
    throw new Error(failStatusMap[res.status])
  }

  let json = {code: '', message: ''}
  try {
    json = await res.json()
  } finally {
  }
  throw new NetworkError(json.code, json.message, res.status)
}


export const sync = async (fn: any, {loading = true} = {}) => {
  let close
  if (loading) {
    close = message.loading({
      content: 'loading...',
      duration: 0,
    })
  }
  try {
    const res = await fn()
    if (close) {
      close()
    }
    return res
  } catch (e) {
    console.log('sync error', e)
    if (close) {
      close()
    }
    const msg = getErrMsg(e)
    if (e instanceof NetworkError) {
      // todo
    }
    notification.error({
      message: 'hint',
      description: msg
    })
  }
}
export const syncWithErrorHandleFunc = async (fn: any, errFunc: any) => {
  
  try {
    const res = await fn()
    return res
  } catch (e) {
    console.log('sync error', e)
    errFunc()
    const msg = getErrMsg(e)
    if (e instanceof NetworkError) {
      // todo
    }
    notification.error({
      message: 'hint',
      description: msg
    })
  }
}

export const syncFn = <T, >(fn: T): T => {
  const call = (...args: any) => sync(async () => {
    // @ts-ignore
    return await fn(...args)
  })
  return call as T
}

function getErrMsg(e: any): string {
  if (typeof e === 'string') {
    return e
  }
  if (e instanceof NetworkError) {
    if (e.code) {
      return `[${e.code}]${e.message}`
    }
    return e.message
  }

  if (e instanceof Error) {
    return `${e.name}: ${e.message}`
  }
  return 'unknown mistake'
}


// form submit wrapper with sync
export const formSubmitWrap = <T, >(fn: (value: any) => void, finallyFunc: ()=> void = ()=>{}) => async (formData: T): Promise<boolean | void> => {
  let isSuccess = false
  await sync(async () => {
    try {
      await fn(formData)
      isSuccess = true
    } finally {
      if(finallyFunc) {
        finallyFunc()
      }
    }
  }, {loading: false})
  return isSuccess
}

// pro table request wrapper with sync
export const tableRequestWrap = (fn: (params: { pageSize?: number, current?: number, [keyword:string]: any }, sort: Record<string, SortOrder>, filter: Record<string, React.ReactText[] | null>) => void) => async <T, >(params: { pageSize?: number, current?: number, keyword?: string }, sort: Record<string, SortOrder>, filter: Record<string, React.ReactText[] | null>): Promise<Partial<RequestData<T>>> => {
  return await sync(async (): Promise<any> => {
    return fn(params, sort, filter)
  }, {loading: false})
}
